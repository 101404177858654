import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import { HiOutlinePhone } from "react-icons/hi"

import CardMember from "../../components/CardMember"
import CardDeck from "react-bootstrap/CardDeck"

import Header from "../../components/Layout/header"
import Footer from "../../components/Layout/footer"
import CallMeBack from "../../components/callback"
import SEO from "../../components/Global/MetaHead"

const RuKontakty = () => {
  const data = useStaticQuery(graphql`
    query {
      ogImage: contentfulAsset(
        file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
      ) {
        title
        id
        fixed(width: 620, height: 460) {
          src
        }
      }
      staff: allContentfulManager(
        filter: { node_locale: { eq: "ru" }, isActive: { eq: true } }
      ) {
        nodes {
          id
          name
          phone
          viber
          description
          binotelClass
          photo {
            fixed(width: 100, height: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      image: contentfulAsset(file: { fileName: { eq: "call.jpeg" } }) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
        id
        fixed(width: 620, height: 460) {
          src
        }
      }
    }
  `)

  const GradientBG = [
    data.image.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const pageSlug = "kontakty"

  return (
    <>
      <SEO
        title="Контакты Центра Лицензий Украины"
        description="Единое Окно: для лицензий Украины. Контакты, адрес, график работы, как найти."
        image={`https:${data.ogImage.fixed.src}`}
        url="/ru/kontakty/"
        lang="ru"
      />
      <Header lang="ru" />
      <BackgroundImage
        fluid={GradientBG}
        className="jumbotron jumbotron-fluid mb-5 contacts-bg"
      >
        <div className="container text-center">
          <h1 className="pb-md-5 pb-sm-3 w-100">Контакты Центра Лицензий</h1>
          <p className="lead pb-3">
            Центр лицензий - всеукраинский онлайн-сервис разрешительных
            документов
          </p>
          <a
            className="btn btn-danger ml-md-3 mt-md-0 mt-3 btn-lg binct-phone-number-1"
            href="tel:+380683328808"
            rel="noopener noreferrer nofollow"
          >
            <HiOutlinePhone />{" "}
            <span className="binct-phone-number-1" id="contactPhone1">
              (068) 332-88-08
            </span>
          </a>
          <a
            className="btn btn-danger ml-md-3 mt-md-0 mt-3 btn-lg binct-phone-number-2"
            href="tel:+380994440124"
            rel="noopener noreferrer nofollow"
          >
            <HiOutlinePhone />{" "}
            <span className="binct-phone-number-2" id="contactPhone2">
              (099) 444-01-24
            </span>
          </a>
          <p></p>
        </div>
      </BackgroundImage>
      <section className="kontakty container mb-5">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <p>
              Работаем одинаково быстро в любом регионе Украины, предоставляем
              профессиональную консультацию.
            </p>
            <h2>Напишите нам</h2>
            <Form
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/dyakuemo/"
              method="post"
              name="contactForm"
              form-name="contactForm"
              id="contactPageFormRu"
              // onSubmit="submit"
            >
              <Form.Group hidden>
                <input type="hidden" name="form-name" value="contactForm" />
                <Form.Label>
                  Поле для незаполнения{" "}
                  <Form.Control type="text" name="bot-field" />
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label>Ваше сообщение</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  name="contactMessage"
                />
              </Form.Group>
              <Form.Row>
                <Col>
                  <Form.Control
                    required
                    name="contactPhone"
                    placeholder="Ваш номер телефона *"
                  />
                </Col>
                <Col>
                  <Form.Control placeholder="Ваше имя" name="contactName" />
                </Col>
              </Form.Row>
              <Form.Group>
                <Form.Text className="text-muted">
                  Отвечаем очень быстро с 10:00 до 18:00. Заявки онлайн
                  принимаются круглосуточно!
                </Form.Text>
              </Form.Group>
              <Button
                variant="warning"
                type="submit"
                className=""
                block
                size="lg"
              >
                Отправить сообщение
              </Button>
            </Form>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="mt-3 text-uppercase">График работы колл-центра</p>
            <ul className="list-unstyled">
              <li>Понеділок — с 10:00 до 18:00</li>
              <li>Вторник — с 10:00 до 18:00</li>
              <li>Среда — с 10:00 до 18:00</li>
              <li>Четверг — с 10:00 до 18:00</li>
              <li>Пятница — с 10:00 до 18:00</li>
              <li>Суббота — с 10:00 до 16:00</li>
              <li>Воскресенье — выходной</li>
            </ul>
            <p>
              Заявки на сайте принимаются <strong>круглосуточно</strong>.
            </p>
            <p>
              ООО «ЦЕНТР ЛІЦЕНЗИЙ»
              <br />
              03164, Украина,
              <br />
              Киев, Подлесная, 1, оф. 8
            </p>
            <p>licenzii.ukr@gmail.com</p>
          </div>
        </div>
      </section>
      <Container as="section">
        <div className="title2 py-5 text-uppercase h3">Наша команда</div>
        <CardDeck className="managers text-white justify-content-center">
          {data.staff.nodes.map(manager => {
            return (
              <CardMember
                key={manager.id}
                fluid={manager.photo.fluid}
                name={manager.name}
                description={manager.description}
                phone={manager.phone}
                viber={manager.viber}
                lang="ru"
                binotelClass={manager.binotelClass}
              />
            )
          })}
        </CardDeck>
      </Container>

      <CallMeBack lang="ru" />
      <Footer slug={`/${pageSlug}/`} lang="ru" />
    </>
  )
}

export default RuKontakty
