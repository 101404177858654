import React, { useState, useRef } from "react"
import BackgroundImage from "gatsby-background-image"

import Card from "react-bootstrap/Card"
// import Dropdown from "react-bootstrap/Dropdown"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Tooltip from "react-bootstrap/Tooltip"
import Overlay from "react-bootstrap/Overlay"

import { HiOutlinePhone, HiOutlineMail, HiBadgeCheck } from "react-icons/hi"
import { SiViber } from "react-icons/si"

// import RoundButton from "./ButtonRound"

const CardMember = ({
  fluid,
  name,
  description,
  phone,
  viber,
  email,
  lang,
  binotelClass,
}) => {
  const backgroundGradient = [
    fluid,
    `linear-gradient(
      to bottom, 
      rgba(128,0,128,0) 30%,
      rgba(35,65,97,1) 100%)`,
  ].reverse()

  const [show, setShow] = useState(false)
  const target = useRef(null)

  return (
    <Col lg={4} md={6} sm={12} className="my-3 px-0 px-sm-2">
      <Card as={BackgroundImage} className="manager" fluid={backgroundGradient}>
        <Card.Body className="d-flex flex-column manager-card-body">
          <Card.Title className="mt-auto">
            {name} <HiBadgeCheck />
          </Card.Title>
          <Card.Text>{description}</Card.Text>
          <Card.Text>
            <Button
              variant="danger"
              className="btn-rounded btn-icon p-3 mr-3 mb-3 mb-sm-0"
              size="lg"
              href={`tel:${phone}`}
            >
              <HiOutlinePhone />
            </Button>

            <Button
              variant="danger"
              className="btn-rounded btn-icon p-3 mr-3 mb-3 mb-sm-0"
              size="lg"
              href={viber}
            >
              <SiViber />
            </Button>
            <Button
              variant="danger"
              className="btn-rounded btn-icon p-3 mr-3 mb-3 mb-sm-0"
              size="lg"
              ref={target}
              onClick={() => {
                navigator.clipboard.writeText(email)
                setShow(!show)
              }}
            >
              <HiOutlineMail />
            </Button>
            <Overlay target={target.current} show={show} placement="top">
              {props => (
                <Tooltip id="overlay-example" {...props}>
                  {lang === "ru"
                    ? `Электронный адрес ${email} скопирован в буфер обмена`
                    : `Електронна адреса ${email} скопійована`}
                </Tooltip>
              )}
            </Overlay>
          </Card.Text>
          {/* <Card.Text className="mt-2">
            <HiOutlinePhone />{" "}
            {phone === "+38(068) 332-88-08" && (
              <a
                className="binct-phone-number-1 text-white"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            )}
            {phone === "+38(099) 444-01-24" && (
              <a
                className="binct-phone-number-2 text-white"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            )}
            {phone === "+38(067) 819-02-72" && (
              <a
                className="binct-phone-number-3 text-white"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            )}
          </Card.Text>
          <Card.Text>
            <a href={viber} className="text-white">
              <SiViber />{" "}
              {lang === "ru" ? `Написать в Viber` : `Написати у Viber`}
            </a>
          </Card.Text> */}

          {/* <RoundButton /> */}
          {/* <Card.Link className={`text-white`} href={`tel:${phone}`}></Card.Link> */}

          {/* <Dropdown
            drop="up"
            onClick={() => {
              window.BinotelCallTracking[508835].replacePhoneNumbersOnDynamicContent()
              console.log("binotel script run")
            }}
          >
            <Dropdown.Toggle variant="danger" id="dropdown-basic" block="true">
              <HiOutlinePhone />
              {lang === "ru"
                ? `${phone.substring(0, 8)} ... показать`
                : `${phone.substring(0, 8)} ... показати`}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dynamic-contacts">
              <Dropdown.Item href={`tel:${phone}`}>
                <HiOutlinePhone className="text-muted" />{" "}
                {phone === "+38(068) 332-88-08" && (
                  <span className="binct-phone-number-1">{phone}</span>
                )}
                {phone === "+38(099) 444-01-24" && (
                  <span className="binct-phone-number-2">{phone}</span>
                )}
                {phone === "+38(067) 819-02-72" && (
                  <span className="binct-phone-number-3">{phone}</span>
                )}
              </Dropdown.Item>
              <Dropdown.Item href={viber}>
                <SiViber className="viber" />{" "}
                {lang === "ru" ? `Написать в Viber` : `Написати у Viber`}
              </Dropdown.Item>
              <Dropdown.Item as="div">
                <HiOutlineMail className="text-muted" /> {email}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </Card.Body>
      </Card>
    </Col>
  )
}

CardMember.defaultProps = {
  fluid: `none`,
  name: `Центр Ліцензій`,
  description: `Єдине вікно. Сервіс реєстрації ліцензій, вся Україна. `,
  phone: `+38(068) 332-88-08`,
  viber: `viber://chat/?number=%2B380683328808"`,
  email: `licenzii.ukr@gmail.com`,
  lang: "uk",
  binotelClass: "binct-phone-number-6",
}

export default CardMember
